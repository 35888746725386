import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { graphql, navigate } from 'gatsby'
import { useTranslation, Trans, useI18next } from 'gatsby-plugin-react-i18next'
import Container from '@material-ui/core/Container'
import SEO from '../../../components/SEO'
import Layout from '../../../components/Layout'
import QuizResultadoCorredor from '../../../components/Quiz/resultadoCorredor'
import QuizResultadoTipoCorrida from '../../../components/Quiz/resultadoTipoCorrida'
import ResetarQuiz from '../../../components/Quiz/resetar'
import Loading from '../../../components/Loading'
import withLocation from '../../../HOC/withLocation'

import * as S from '../styles'

const SharedQuizResult = ({ search }) => {
  const { t } = useTranslation()
  const { language } = useI18next()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!search.p && !search.s) {
      navigate(`/${language}/quiz`)
    } else {
      setLoading(false)
    }
  }, [search, language])

  if (loading) {
    return <Loading />
  }

  return (
    <Layout>
      <SEO title={t('quiz_title_seo')} description={t('description_seo')} />
      <S.Bg isCorredor>
        <Container>
          <S.PageTitle isCorredor>
            <strong>
              <Trans>quiz concluído com sucesso!</Trans>
            </strong>
          </S.PageTitle>
          <S.PageSubTitle isCorredor>
            <Trans>Confira os resultados</Trans>
          </S.PageSubTitle>
          <S.QuizWrapper>
            <QuizResultadoCorredor segmentoVencedor={Number(search.s)} />
            <QuizResultadoTipoCorrida
              pontuacaoTotalCorrida={Number(search.p)}
            />
            <ResetarQuiz shareParams={null} />
          </S.QuizWrapper>
        </Container>
      </S.Bg>
    </Layout>
  )
}

SharedQuizResult.propTypes = {
  search: PropTypes.object,
}

export default withLocation(SharedQuizResult)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
