import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { FaFacebookF } from '@react-icons/all-files/fa/FaFacebookF'
import { FaWhatsapp } from '@react-icons/all-files/fa/FaWhatsapp'
import { FaTwitter } from '@react-icons/all-files/fa/FaTwitter'

import * as S from './styles'

const ShareSocialMedia = ({ url, texto }) => {
  const { t } = useTranslation()

  return (
    <S.Share>
      <Trans>Compartilhar</Trans>
      <S.LinkSocialShare
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          url
        )}&quote=${encodeURIComponent(`${texto} - ${t('share_placeholder')}`)}`}
        className="first"
        rel="noopener noreferrer nofollow"
        aria-label="Compartilhar no Facebook"
        target="_blank"
      >
        <FaFacebookF />
      </S.LinkSocialShare>
      <S.LinkSocialShare
        href={`https://wa.me/?text=${
          texto
            ? `${encodeURIComponent(texto)} - ${t('share_placeholder')} `
            : ''
        }${encodeURIComponent(url)}`}
        className="second"
        rel="noopener noreferrer nofollow"
        aria-label="Compartilhar no Whatsapp"
        target="_blank"
      >
        <FaWhatsapp />
      </S.LinkSocialShare>
      <S.LinkSocialShare
        href={`https://twitter.com/intent/tweet?text=${
          texto
            ? `${encodeURIComponent(texto)} - ${t('share_placeholder')}`
            : ''
        }${encodeURIComponent(url)}`}
        className="third"
        rel="noopener noreferrer nofollow"
        aria-label="Compartilhar no Twitter"
        target="_blank"
      >
        <FaTwitter />
      </S.LinkSocialShare>
    </S.Share>
  )
}

ShareSocialMedia.propTypes = {
  url: PropTypes.string.isRequired,
  texto: PropTypes.string,
}

ShareSocialMedia.defaultProps = {
  texto: undefined,
}

export default ShareSocialMedia

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
