import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Trans, Link, useI18next } from 'gatsby-plugin-react-i18next'
import ShareSocialMedia from '../Buttons/share'

import * as S from './styles'

const ResetarQuiz = ({ shareParams }) => {
  const { language } = useI18next()
  const url = window.location.origin
  let shareUrl

  if (shareParams?.segmentoVencedor) {
    shareUrl = `${url}/${language}/quiz/s/resultado?s=${shareParams?.segmentoVencedor}&p=${shareParams?.pontuacaoTotalCorrida}`
  } else {
    shareUrl = `${url}/${language}/quiz/s/resultado?p=${shareParams?.pontuacaoTotalCorrida}`
  }

  return (
    <S.ButtonsWrapper className="resete-wrapper">
      <Link className="botao-resetar" to="/quiz">
        <Trans>Refazer quiz</Trans>
      </Link>
      <Link className="botao-resetar" to="/">
        <Trans>voltar a página inicial</Trans>
      </Link>
      {shareParams !== null && <ShareSocialMedia url={shareUrl} />}
    </S.ButtonsWrapper>
  )
}

ResetarQuiz.propTypes = {
  shareParams: PropTypes.shape({
    pontuacaoTotalCorrida: PropTypes.number.isRequired,
  }),
}

export default ResetarQuiz

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
