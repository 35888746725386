import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Trans } from 'gatsby-plugin-react-i18next'
import NavLink from '../Buttons'
import { GlobalContext } from '../../Context/global'
import and from 'and'
import * as S from './styles'

const QuizVitrine = ({ vitrine }) => {
  const { language } = useContext(GlobalContext)
  const data = useStaticQuery(graphql`
    query {
      allProduto {
        edges {
          node {
            pais
            alternative_id
            slug
            nome
            solado
            drop
            cabedal
            entressola
            peso
            indicadoPara
            tipoPisada
            localFoto {
              childImageSharp {
                gatsbyImageData(
                  width: 160
                  height: 86
                  transformOptions: { cropFocus: CENTER }
                  layout: FIXED
                  placeholder: BLURRED
                  quality: 80
                )
              }
            }
          }
        }
      }
    }
  `)


  return (
    <>
      {vitrine.length ? (
        <div className="boxquizresultado">
          {data.allProduto.edges
            .filter((item) => item.node.pais === language)
            .map((item) => {
              if (vitrine.includes(item.node.slug)) {
                return (
                  <S.Card className="active">
                    <S.Modelo>{item.node.nome}</S.Modelo>
                    <S.WrapperThumb>
                      <GatsbyImage
                        image={getImage(item.node.localFoto)}
                        alt={item.node.nome}
                      />
                    </S.WrapperThumb>
                    <S.IndicadoPara>
                      <h3>
                        <Trans>Indicado para:</Trans>
                      </h3>
                      {console.log(item.peso)}
                      {item.node.indicadoPara && (
                        <S.IndicadoPara>
                          <p>{item.node.indicadoPara}</p>
                        </S.IndicadoPara>
                      )}
                    </S.IndicadoPara>
                    <S.FeaturesWrapper>
                      {item.node.drop && (
                        <S.Features
                          style={{ backgroundColor: '#E6E5F2', color: '#001E62' }}
                        >
                          <strong>Drop</strong>
                          {item.node.drop}
                        </S.Features>
                      )}
                      {item.node.cabedal  && (
                        <S.Features
                          style={{ backgroundColor: '#DCEEE2', color: '#001E62' }}
                        >
                          <strong>
                            <Trans>Cabedal</Trans>
                          </strong>
                          {item.node.cabedal}
                        </S.Features>
                      )}
                      {item.node.entressola && (
                        <S.Features
                          style={{ backgroundColor: '#FAF8DD', color: '#001E62' }}
                        >
                          <strong>
                            <Trans>Entressola</Trans>
                          </strong>
                          {item.node.entressola}
                        </S.Features>
                      )}
                      {item.node.solado && (
                        <S.Features
                          style={{ backgroundColor: '#FFECDC', color: '#001E62' }}
                        >
                          <strong>
                            <Trans>Solado</Trans>
                          </strong>
                          {item.node.solado}
                        </S.Features>
                      )}
                      {item.node.tipoPisada && (
                        <S.Features
                          style={{ backgroundColor: '#E6E5F2', color: '#001E62' }}
                        >
                          <strong>
                            <Trans>Pisada</Trans>
                          </strong>
                          {item.node.tipoPisada}
                        </S.Features>
                       )}
                       {item.node.peso && (
                        <S.Features
                        style={{ backgroundColor: '#DCEEE2', color: '#001E62' }}
                        >
                          <strong>
                            <Trans>Peso</Trans>
                          </strong>
                          {item.node.peso}
                        </S.Features>
                      )}
                      {item.node.amortecimento && (
                       <S.Features
                          style={{
                            backgroundColor: '#FFECDC',
                            color: '#001E62'
                          }}
                        >
                          <strong>
                            <Trans>Amortecimento</Trans>
                          </strong>
                          {item.node.amortecimento}
                        </S.Features>
                      )}
                    </S.FeaturesWrapper>
                    <S.LinksWrapper>
                      <NavLink to={`/quiz/oferta`}  id="btn-oferta">
                        <Trans>comprar com benefício</Trans>
                      </NavLink>
                    </S.LinksWrapper>
                  </S.Card>
                )
              }
            })}
        </div>
      ) : null}
    </>
  )
}

QuizVitrine.propTypes = {
  vitrine: PropTypes.array.isRequired,
}

export default QuizVitrine
