import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useI18next } from 'gatsby-plugin-react-i18next'

import segmento1 from '../../assets/images/quiz/segmento1.png'
import segmento2 from '../../assets/images/quiz/segmento2.png'
import segmento3 from '../../assets/images/quiz/segmento3.png'
import segmento4 from '../../assets/images/quiz/segmento4.png'

import * as S from './styles'

function getThumbs(segmento) {
  const Thumbs = {
    segmento1,
    segmento2,
    segmento3,
    segmento4,
  }

  return Thumbs[segmento]
}

const QuizResultadoCorredor = ({ segmentoVencedor = null }) => {
  const { language } = useI18next()

  const data = require(`../../data/ResultadoTipoCorredor_${language}.json`)

  return (
    <S.ResultadoCorredor>
      {data.map((resultado) => {
        if (resultado.segmento === segmentoVencedor + 1) {
          return (
            <Fragment key={resultado.segmento}>
              <img
                src={getThumbs(`segmento${segmentoVencedor + 1}`)}
                alt={resultado.titulo}
              />
              <S.TextResultado>
                <h2>{resultado.titulo}</h2>
                <p dangerouslySetInnerHTML={{ __html: resultado.texto }} />
              </S.TextResultado>
            </Fragment>
          )
        }
      })}
    </S.ResultadoCorredor>
  )
}

QuizResultadoCorredor.propTypes = {
  segmentoVencedor: PropTypes.number,
}

export default QuizResultadoCorredor
